import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"
import { Button, Flex, Image, Text } from "rebass"
import LSJLogo from "../../assets/images/lsj-logo.svg"
import qs from "query-string"

import Medusa from "../../services/api"
import StoreContext from "../../context/store-context"

const Container = styled(Flex)`
  width: 100%;
  height: 100vh;
  background-color: #eeece6;
  flex-direction: column;

  color: #00003a;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
`

const StyledImage = styled(Image)`
  cursor: pointer;
  max-height: 100px;
  margin-bottom: 50px;
`

const Payment = (props) => {
  const [order, setOrder] = useState()

  useEffect(() => {
    fetchOrder()
  }, [])

  const fetchOrder = async () => {
    const { o } = qs.parse(props.location.search)
    if (o) {
      Medusa.orders.retrieve(o).then(({ data }) => {
        setOrder(data.order)
      })
    }
  }

  return (
    <Container>
      <Helmet title={"Thank you! | LSJ"} />
      <Flex
        alignItems="center"
        flexDirection="column"
        height="60%"
        justifyContent="center"
      >
        {order && (
          <>
            <StyledImage src={LSJLogo} onClick={() => navigate(`/`)} />
            <h1>Thank you, {order.shipping_address.first_name}!</h1>
            <div>You will receive an order confirmation shortly!</div>
          </>
        )}
      </Flex>
    </Container>
  )
}

export default Payment
